import React from 'react'
import styled from 'styled-components'

import NueProprieteBackground from '../../molecules/NueProprieteBackground'
import NueProprieteSidebarForm from '../../molecules/NueProprieteSidebarForm'
import NueProprieteSidebarTitle from '../../molecules/NueProprieteSidebarTitle'

const SUBMIT = 'https://hooks.zapier.com/hooks/catch/2114713/bp0dzy9'

const Container = styled.div`
  display: flex;
`

const FormSection = styled.div`
  padding: 32px 40px;
  height: 100vh;
  overflow: auto;
  max-width: 420px;
`

const NueProprieteDesktop = ({ id }) => (
  <Container>
    <NueProprieteBackground id={id} />
    <FormSection>
      <NueProprieteSidebarTitle color='blue' />
      <NueProprieteSidebarForm submit={SUBMIT} isWhite={false} />
    </FormSection>
  </Container>
)

export default NueProprieteDesktop
