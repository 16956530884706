import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get } from 'lodash'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
  gap: 16px;
`

const Title = styled.span`
  font-family: 'Source Sans Pro';
  font-size: 26px;
  font-weight: bold;
  line-height: 1.32;
  color: ${({ theme, color }) => get(theme, color, '#FFF')};

  & > b {
    color: ${({ theme }) => get(theme, 'brightOrange')};
  }
`

const Subtitle = styled.span`
  font-family: 'Source Sans Pro';
  font-size: 17px;
  font-weight: 600;
  line-height: 1.61;
  color: ${({ theme, color }) => get(theme, color, '#FFF')};
`

const NueProprieteSidebarTitle = ({ color }) => (
  <Container>
    <Title color={color}>
      Développez votre patrimoine en investissant sur la<br/><b>NUE-PROPRIÉTÉ</b>
    </Title>
    <Subtitle color={color}>
    Réussissez votre projet avec nos experts qui identifieront le produit adapté à vos besoins
    </Subtitle>
  </Container>
)

NueProprieteSidebarTitle.propTypes = {
  color: PropTypes.string
}

NueProprieteSidebarTitle.defaultProps = {
  color: 'white'
}

export default NueProprieteSidebarTitle
