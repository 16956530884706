import React, { useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import SEO from '../../atoms/Seo'
import JsonLD from '../../atoms/JsonLD'
import media from '../../../utils/media'
import NueProprieteDesktop from '../../organisms/NueProprieteDesktop'
import NueProprieteMobile from '../../organisms/NueProprieteMobile'
import NueProprieteMobileButton from '../../organisms/NueProprieteMobileButton'

const TITLE = 'Formulaire Nue-Propriété'
const DESCRIPTION = 'Développez votre patrimoine en investissant sur la nue-propriété avec Folhomee'

const DesktopContainer = styled.div`
  display: block;

  ${media.lessThan('lg')`
    display: none;
  `}
`

const MobileContainer = styled.div`
  display: block;

  ${media.greaterThan('lg')`
    display: none;
  `}
`

const NuePropriete = ({ pageContext: { id }, location }) => {
  const hiddenRef = useRef()
  const linkedData = {
    '@context': 'http://schema.org',
    '@type': 'Project',
    name: 'Folhomee',
    description: DESCRIPTION,
    parentOrganization: {
      '@type': 'Organization',
      name: 'Folhomee',
      location: {
        '@type': 'PostalAddress',
        streetAddress: '44 Rue du Chemin Vert',
        addressLocality: 'Paris',
        postalCode: '75011'
      }
    },
    keywords: 'Programme immobilier, Folhomee, définition nue propriété, accompagnement'
  }

  return (
    <>
      <SEO
        title={TITLE}
        location={location}
        description={DESCRIPTION}>
        <JsonLD>
          {linkedData}
        </JsonLD>
      </SEO>
      <DesktopContainer>
        <NueProprieteDesktop id={id} />
      </DesktopContainer>
      <MobileContainer>
        <NueProprieteMobileButton
          label='Envoyer'
          hiddenRef={hiddenRef} />
        <NueProprieteMobile
          id={id}
          hiddenRef={hiddenRef} />
      </MobileContainer>
    </>
  )
}

NuePropriete.propTypes = {
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default NuePropriete
