import React from 'react'
import styled from 'styled-components'

import Logo from '../../atoms/Logo'
import media from '../../../utils/media'
import NueProprieteBackground from '../../molecules/NueProprieteBackground'
import NueProprieteSidebarForm from '../../molecules/NueProprieteSidebarForm'
import NueProprieteSidebarTitle from '../../molecules/NueProprieteSidebarTitle'

const SUBMIT = 'https://hooks.zapier.com/hooks/catch/2114713/bp0dzy9'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const TopSection = styled.div`
  padding: 24px 72px;

  ${media.lessThan('md')`
    padding: 24px 40px;
  `}

  ${media.lessThan('sm')`
    padding: 12px 24px 12px 24px;
  `}
`

const LogoContainer = styled.div`
  margin-bottom: 32px;
  max-width: 200px;
`

const FormSection = styled.div`
  padding: 0 32px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const NueProprieteMobile = ({ id, hiddenRef }) => (
  <Container>
    <TopSection>
      <LogoContainer>
        <Logo classic={true} />
      </LogoContainer>
      <NueProprieteSidebarTitle color='blue' />
    </TopSection>
    <NueProprieteBackground id={id} />
    <FormSection>
      <NueProprieteSidebarForm
        submit={SUBMIT}
        isWhite={false}
        hiddenRef={hiddenRef} />
    </FormSection>
  </Container>
)

export default NueProprieteMobile
