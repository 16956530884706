import React from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import { useStaticQuery, graphql } from 'gatsby'
import { get, nth, first } from 'lodash'

import Logo from '../../atoms/Logo'
import media from '../../../utils/media'

const StyledImageSection = styled(BackgroundImage)`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  justify-content: space-between;
  min-height: 440px;

  ${media.greaterThan('lg')`
    height: 100vh;
    justify-content: flex-start;
    position: fixed;
    overflow-y: auto;
    top: 0; right: 0; bottom: 0; left: 0;
  `}
`

const LogoContainer = styled.div`
  max-width: 250px;
  margin-left: 0.5rem;
  ${media.lessThan('lg')`
    display: none;
  `}
`

const StyledLogo = styled(Logo)`
  width: auto;
  height: 100%;
`

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  ${media.greaterThan('lg')`
    padding-top: 55px;
    padding-left: 15px;
  `}
`

const DataItem = styled.div`
  max-width: 310px;
  border-radius: 15px;
  padding: 11px 16px;
  background-color: ${({ theme }) => get(theme, 'white', '#FFF')};
`

const DataText = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 18px;
  font-weight: 600;
  line-height: 1.26;
  color: ${({ theme }) => get(theme, 'blue')};

  ${media.lessThan('lg')`
    font-size: 16px;
    line-height: 1.19;
  `}

  & > b {
    color: ${({ theme }) => get(theme, 'brightOrange')};
  }

  & > span {
    font-size: 16px;
    font-weight: normal;

    ${media.lessThan('lg')`
      font-size: 14px;
    `}
  }
`

const NueProprieteBackground = ({ id }) => {
  const { images } = useStaticQuery(graphql`{
    images: allFile(
      filter: { relativeDirectory: { eq: "nue-propriete" } }
      sort: { fields: relativePath }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            layout: FULL_WIDTH
          )
        }
      }
    }
  }`)

  const image = getImage(nth(get(images, 'nodes'), id - 1) || first(get(images, 'nodes')))
  const bgImage = convertToBgImage(image)
  const logoColor = [2, 5].includes(id)

  return (
    <StyledImageSection
      alt='Programmes immobilier neufs'
      Tag='div'
      {...bgImage}
      preserveStackingContext>
        <LogoContainer>
          <StyledLogo
            blue={logoColor}
            classic={false} />
        </LogoContainer>
        <DataContainer>
          <DataItem>
            <DataText>
              <b>Acquérir de l&apos;immobilier</b> patrimonial en coeur de ville <br /> <span>dans des conditions exceptionnelles</span>
            </DataText>
          </DataItem>
          <DataItem>
            <DataText>
              Un investissement sécurisé <br /> <span>sans contrainte de gestion</span>
            </DataText>
          </DataItem>
          <DataItem>
            <DataText>
              Un cadre fiscal avantageux <br /> <span>lors d&apos;une donation ou d&apos;une succession</span>
            </DataText>
          </DataItem>
        </DataContainer>
      </StyledImageSection>
  )
}

export default NueProprieteBackground